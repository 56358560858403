/**
 * @file Gravity Forms customization.
 */

// import { gsap } from 'gsap';

/**
 * Gravity Form component.
 */
const vGravityForms = () => {
	const { gform } = window;

	if (!gform || !window.jQuery) {
		return;
	}

	const handleFormLabels = () => {
		const fields = document.querySelectorAll(
			`.gfield--type-text, .gfield--type-phone, .gfield--type-email, .gfield--type-website, .gfield--type-number, .gform-grid-col[class*='address_']`,
		);

		[...fields].forEach((f) => {
			const field = f.querySelector('input') || f.querySelector('select');

			if (!field) {
				return;
			}

			const label = f.querySelector('label');

			// If field is focused animate the label
			field.addEventListener('focus', () => {
				if (!label.classList.contains('float-up')) {
					label.classList.add('float-up');
				}
			});

			// If field is blurred & does not have a value, animate the label
			field.addEventListener('blur', () => {
				if (!field.value.trim() && label.classList.contains('float-up')) {
					label.classList.remove('float-up');
				}
			});

			// on load, if field has a value, animate the label
			if (field.value.trim() && !label.classList.contains('float-up')) {
				label.classList.add('float-up');
			}
		});
	};

	window.jQuery(document).on('gform_post_render', () => {
		handleFormLabels();

		const fileUpload = document.querySelector(
			'.gfield--type-fileupload input[type="file"]',
		);

		if (fileUpload) {
			const parent = fileUpload.parentElement;
			fileUpload.addEventListener('change', () => {
				const fileName = fileUpload.files[0].name;

				// set parent's custom property to the file name
				parent.style.setProperty('--v-upload-button-text', `'${fileName}'`);
				parent.classList.add('file-selected');
			});
		}
	});

	// console.log(fields);

	/*
	|
	| Animate labels of regular fields
	|
	*/
	// fields.forEach((el) => {
	// 	const label = el.querySelector('label');
	// 	const input = el.querySelector('input');
	// 	const initialHeight = 5;
	// 	const clickedHeight = -20;

	// 	gsap.set(label, {
	// 		y: initialHeight,
	// 	});

	// 	input.addEventListener('focus', () => {
	// 		// Set labels back to initial position only if their corresponding inputs are empty
	// 		fields.forEach((field) => {
	// 			const otherLabel = field.querySelector('label');
	// 			const otherInput = field.querySelector('input');
	// 			if (otherInput.value.trim() === '') {
	// 				gsap.to(otherLabel, {
	// 					y: initialHeight,
	// 					duration: 0.2,
	// 				});
	// 			}
	// 		});

	// 		// Animate only the clicked field's label
	// 		gsap.to(label, {
	// 			y: clickedHeight,
	// 			duration: 0.2,
	// 		});
	// 	});

	// 	input.addEventListener('input', () => {
	// 		// Keep the label on top if input is not empty
	// 		if (input.value.trim() !== '') {
	// 			gsap.to(label, {
	// 				y: clickedHeight,
	// 				duration: 0.2,
	// 			});
	// 		}
	// 	});

	// 	// Reset the label when input loses focus only if the input is empty
	// 	input.addEventListener('blur', () => {
	// 		if (input.value.trim() === '') {
	// 			gsap.to(label, {
	// 				y: initialHeight,
	// 				duration: 0.2,
	// 			});
	// 		}
	// 	});

	// 	// Check on load and adjust label position if input is filled
	// 	console.log(input.value.trim());
	// 	if (input.value.trim() !== '') {
	// 		gsap.to(label, {
	// 			y: clickedHeight,
	// 			duration: 0.2,
	// 		});
	// 	}
	// });

	/*
	|
	| Animate labels of address fields
	|
	*/
	// addressWrapper.forEach((el) => {
	// 	const label = el.querySelector('label');
	// 	const input = el.querySelector('input');
	// 	const initialHeight = -33;
	// 	const clickedHeight = -60;

	// 	gsap.set(label, {
	// 		y: initialHeight,
	// 	});

	// 	input.addEventListener('focus', () => {
	// 		// Set labels back to initial position only if their corresponding inputs are empty
	// 		addressWrapper.forEach((field) => {
	// 			const otherLabel = field.querySelector('label');
	// 			const otherInput = field.querySelector('input');
	// 			if (otherInput.value.trim() === '') {
	// 				gsap.to(otherLabel, {
	// 					y: initialHeight,
	// 					duration: 0.2,
	// 				});
	// 			}
	// 		});

	// 		// Animate only the clicked field's label
	// 		gsap.to(label, {
	// 			y: clickedHeight,
	// 			duration: 0.2,
	// 		});
	// 	});

	// 	input.addEventListener('input', () => {
	// 		// Keep the label on top if input is not empty
	// 		if (input.value.trim() !== '') {
	// 			gsap.to(label, {
	// 				y: clickedHeight,
	// 				duration: 0.2,
	// 			});
	// 		}
	// 	});

	// 	// Reset the label when input loses focus only if the input is empty
	// 	input.addEventListener('blur', () => {
	// 		if (input.value.trim() === '') {
	// 			gsap.to(label, {
	// 				y: initialHeight,
	// 				duration: 0.2,
	// 			});
	// 		}
	// 	});

	// 	// Check on load and adjust label position if input is filled
	// 	if (input.value.trim() !== '') {
	// 		gsap.to(label, {
	// 			y: clickedHeight,
	// 			duration: 0.2,
	// 		});
	// 	}
	// });

	/*
	|
	| Set label of address' country select field
	|
	*/
	// addressWrapperCountry.forEach((el) => {
	// 	const label = el.querySelector('label');
	// 	const select = el.querySelector('select');
	// 	const initialPositionY = -40;
	// 	const raisedPositionY = -60; // Adjust this value as needed for the raised position

	// 	gsap.set(label, {
	// 		y: initialPositionY,
	// 		width: 'fit-content', // so it can still be clicked
	// 	});

	// 	// Event listener for change on the select to handle user selections
	// 	select.addEventListener('change', () => {
	// 		if (select.value) {
	// 			// If a selection has been made
	// 			gsap.to(label, {
	// 				y: raisedPositionY,
	// 				duration: 0.2,
	// 			});
	// 		} else {
	// 			// If the selection is cleared or reset to default with no value
	// 			gsap.to(label, {
	// 				y: initialPositionY,
	// 				duration: 0.2,
	// 			});
	// 		}
	// 	});

	// 	// Check if the select field has a value on page load/refresh
	// 	if (select.value) {
	// 		gsap.to(label, {
	// 			y: raisedPositionY,
	// 			duration: 0.2,
	// 		});
	// 	}
	// });

	/*
	|
	| Create top section wrapper for Job Application Form
	|
	*/
	// Select the elements
	const topBlockText = document.querySelector(
		'.v-form-job-application__top-block-text',
	);
	const uploadResumeButton = document.querySelector(
		'.v-form__job-application-upload-resume-button',
	);

	// Check if both elements exist on job app form
	if (topBlockText && uploadResumeButton) {
		// Create the wrapper div and add the class
		const wrapperDiv = document.createElement('div');
		wrapperDiv.classList.add('v-form-job-application__top-wrapper');

		// Insert the wrapper before the first element
		topBlockText.parentNode.insertBefore(wrapperDiv, topBlockText);

		// Move both elements into the wrapper
		wrapperDiv.appendChild(topBlockText);
		wrapperDiv.appendChild(uploadResumeButton);

		// New code to wrap other .gfield elements
		const gfields = document.querySelectorAll(
			'.gfield:not(.v-form-job-application__top-block-text.gfield):not(.v-form__job-application-upload-resume-button.gfield)',
		);

		if (gfields.length > 0) {
			const bottomWrapperDiv = document.createElement('div');
			bottomWrapperDiv.classList.add('v-form-job-application__bottom-wrapper');

			// Assuming the first gfield has a parent where we can insert the bottomWrapperDiv
			gfields[0].parentNode.insertBefore(bottomWrapperDiv, gfields[0]);

			gfields.forEach((gfield) => {
				bottomWrapperDiv.appendChild(gfield);
			});
		}
	}
};

export default vGravityForms;
