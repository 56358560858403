/**
 * @file Card Link.
 */

import { gsap, ScrollTrigger } from 'gsap';

gsap.registerPlugin(ScrollTrigger);

/**
 * Card Link component.
 */
const vCardLink = () => {
    const cardLinks = document.querySelector('.v-card-links');

    if (!cardLinks) {
        return;
    }

    const cardsHoverAnimation = () => {
        const singleCard = document.querySelectorAll('.v-card-link');
				let isMobile = window.innerWidth < 769;
        window.addEventListener('resize', () => {
					isMobile = window.innerWidth < 769
				});

        singleCard.forEach((el) => {
            const singleCardBtn = el.querySelector('.v-card-link__link');
            const singleCardContent = el.querySelector('.v-card-link__content');
            const singleCardTitle = el.querySelector('.v-card-link__title');
            const singleCardText = el.querySelector('.v-card-link__text');
            const singleCardTextHide = el.classList.contains('v-card-link__title-only');

            const cardHeight = el.offsetHeight;
            const cardContentHeight = singleCardContent.offsetHeight;
            const cardTitleHeight = singleCardTitle.offsetHeight;

            // Calculate the centered top position
            const centeredTop = (cardHeight - cardContentHeight) / 2;
            const centeredTopTitleOnly = (cardHeight - cardTitleHeight) / 2;

            // Set initial states
            if (singleCardTextHide) {
                gsap.set(singleCardContent, {
                    top: isMobile ? centeredTop : centeredTopTitleOnly,
                });
                gsap.set([singleCardText, singleCardBtn], {
                    autoAlpha: isMobile ? 1 : 0,
                });
            } else {
                gsap.set(singleCardContent, {
                    top: isMobile ? centeredTop : centeredTopTitleOnly,
                });
								if(singleCardText) {
									gsap.set([singleCardText, singleCardBtn], {
											autoAlpha: isMobile ? 1 : 0,
									});
								} else {
									gsap.set([singleCardBtn], {
										autoAlpha: isMobile ? 1 : 0,
								});
								}
            }

            // Mouse enter event (only for larger screens)
            el.addEventListener('mouseenter', () => {
                if (!isMobile) {
                    gsap.to(singleCardContent, {
                        top: centeredTop,
                        duration: 0.5,
                        overwrite: 'auto',
                    });
                    gsap.to([singleCardBtn, singleCardText], {
                        autoAlpha: 1,
                        duration: 0.3,
                        overwrite: 'auto',
                    });
                }
            });

            // Mouse leave event (only for larger screens)
            el.addEventListener('mouseleave', () => {
                if (!isMobile) {
                    if (singleCardTextHide) {
                        gsap.to(singleCardContent, {
                            top: centeredTopTitleOnly,
                            duration: 0.3,
                            overwrite: 'auto',
                        });
                        gsap.to([singleCardBtn, singleCardText], {
                            autoAlpha: 0,
                            duration: 0.3,
                            overwrite: 'auto',
                        });
                    } else {
                        gsap.to(singleCardContent, {
                            top: centeredTopTitleOnly,
                            duration: 0.3,
                            overwrite: 'auto',
                        });
                        gsap.to([singleCardBtn, singleCardText], {
                            autoAlpha: 0,
                            duration: 0.3,
                            overwrite: 'auto',
                        });
                    }
                }
            });
        });
    };

    /**
     *
     * @param func
     * @param delay
     */
    function callAfterResize(func, delay) {
        const dc = gsap.delayedCall(delay || 0.2, func).pause();
        const handler = () => dc.restart(true);
        window.addEventListener('resize', handler);
        return handler; // in case you want to window.removeEventListener() later
    }

    callAfterResize(cardsHoverAnimation);

    cardsHoverAnimation();
};

export default vCardLink;
